@import 'variables';

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1rem;
  justify-content: center;
  gap: .5rem;
  li {
    position: relative;
    margin-right: 10px;
    padding-left: 10px;
  }
  li:not(:last-child):after {
    content: ">";
    position: absolute;
    top: 0;
    margin-left: 100%;
  }
  li:last-child {
    > a {
      pointer-events: none;
      cursor: none;
    }
  }
}

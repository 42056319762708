.component-modal:not(.component-modal-forced):not(:last-of-type) {
  .modal {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  user-select: none;

  &.open-modal &__overlay,
  &.open-modal {
    display: block;
    z-index: 105;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $background;
    display: none;
  }

  &__close {
    position: absolute;
    align-items: baseline;
    margin: 0;
    top: 0;
    right: 21px;

    &:hover {
      opacity: $opacity;
    }
  }

  &.open-modal &__wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__wrap {
    display: none;
    box-shadow: none;
    z-index: 106;
    background-color: $color2;
    border-radius: $border-radius;
    border: 1rem solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 800px;

		&_switch {
			display: flex;
		  flex-direction: column;
		  gap: 1rem;
		}

    &_info {
      max-width: 800px;

      .modal__content {
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: .5rem;

        li:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__header {
    margin-top: 2rem;

    &-title {
      font-size: $font-size4;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      gap: .5rem;

      &::first-letter {
        text-transform: uppercase;
      }

      &_major {
        font-size: $font-size5;
      }
    }
  }

  &__content {
    overflow: auto;
    max-block-size: calc(100% - 1rem);
    color: $color;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    &-right {
      text-align: right;
    }

    p {
      margin: .5rem 0 0;
      line-height: 1.4;

      a {
        border-bottom: 1px solid;
        display: inline;
      }
    }

    ul {
      margin: .5rem 0 0 2rem;
    }

    li {
      list-style: disc;
      margin-bottom: .5rem;
      line-height: 1.4;
    }

    h2,
    h3 {
      margin: .5rem 0 0;
      font-size: $font-size3;
      font-weight: 400;
    }

    h4 {
      margin: .5rem 0 0;
	    font-size: $font-size;
	    font-weight: 400;
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: $border-radius;
    }

    textarea {
      padding: .5rem;
      resize: none;
      width: 100%;
      height: $max-height;
      display: block;
      border: 1px solid $color;
      border-radius: $border-radius;
      font-family: $font-family;
      font-size: $font-size;
      color: $color;
    }

    &-item {
      &_textarea {
        width: 100%;
      }

      &_caption {
        font-size: $font-size6;
        margin-top: .5rem;
        display: flex;
        justify-content: center;
        text-align: center;

        a {
          color: $color4;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    &-col {
      display: flex;
      align-items: center;
    }

    &-buttons {
      display: flex;
      align-items: baseline;
      gap: 1rem;

      &.modal__footer-buttons_cols {
        justify-content: space-between;
      }
    }

    &-button {
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 600;

			&:hover {
				opacity: $opacity;
			}

      &_primary {
        color: $color;
        background: transparent;
        border: solid;
        border-width: 1.5px;
        white-space: nowrap;
        touch-action: manipulation;
        padding: .5rem 1rem;
        border-radius: $border-radius2;
      }
    }
  }
}

body.has-modal-global-scroll {
  overflow: hidden;
}

.modal {
  &.modal-global-scroll.open-modal &__overlay {
    display: flex;
  }

  &.modal-global-scroll &__overlay {
    overflow-x: hidden;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    top: 0;
    width: 100%;
  }

  &.modal-global-scroll &__wrap {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin: 1rem 1rem 2rem;
  }

  &.modal-global-scroll &__content {
    overflow: hidden;
    max-height: unset;
  }
}

.modal {
  .options__item {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    label {
      margin: 0 1rem 0 0;
    }
  }
}